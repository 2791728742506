'use client';

// Libraries
import dynamic from 'next/dynamic';

// Constants
import { LAYOUT, PAGE } from '@/constants/config';

// Components
import Loading from '@/components/Loading';

// Variables
const TopBanner = dynamic(() => import('@/components/Home/TopBanner'), {
  ssr: false,
  loading: () => <Loading />,
});
const TopContent = dynamic(() => import('@/components/Home/TopContent'), {
  ssr: false,
  loading: () => <Loading />,
});
const Integration = dynamic(() => import('@/components/Home/Integration'), {
  ssr: false,
  loading: () => <Loading />,
});
const Support = dynamic(() => import('@/components/Home/Support'), {
  ssr: false,
  loading: () => <Loading />,
});
const Partners = dynamic(() => import('@/components/Home/Partners'), {
  ssr: false,
  loading: () => <Loading />,
});
const ContactUs = dynamic(() => import('@/components/ContactUs'), {
  ssr: false,
  loading: () => <Loading />,
});

export default function Home() {
  return (
    <article className="home">
      <TopBanner />
      <TopContent />
      <Integration />
      <Support />
      <Partners />
      <ContactUs />
    </article>
  );
}

Home.layout = LAYOUT.HEADER_TRANSPARENT;
Home.title = 'common.meta.page.home';
